import * as Schema from 'schema-dts'
import { hasValue } from 'utils/assertions'
import { JsonLD, JsonLDPlugin } from 'utils/structuredData/plugins'
import { withMainEntityOfPage } from 'utils/structuredData/plugins/withMainEntityOfPage'
import { withNormalization } from 'utils/structuredData/plugins/withNormalization'
import { withSchemaContext } from 'utils/structuredData/plugins/withSchemaContext'

export const personSchema = ({ name }: { name: string }): Schema.Person => {
  return {
    '@type': 'Person',
    name,
  }
}

export const reviewSchema = ({
  author,
  reviewBody,
  datePublished,
}: {
  author: Schema.Person
  reviewBody: string
  datePublished: string
}): Schema.Review => {
  return {
    '@type': 'Review',
    author,
    reviewBody,
    datePublished,
  }
}

/**
 * Bundles together a list of JSON-LD objects, making them ready to include
 * in structured data / `application/ld+json` script tags.
 *
 * This function is a wrapper around a series of
 * plugins that transform the JSON-LD objects in various ways. The default plugins are:
 *
 * 1. {@link withMainEntityOfPage} - Adds `mainEntityOfPage` to objects with pages
 * 2. {@link withNormalization} - Normalizes JSON-LD objects to reduce duplication
 * 3. {@link withSchemaContext} - Adds `@context` to all root objects
 *
 * You can also pass in custom plugins to further modify the JSON-LD objects.
 * These plugins will be applied before the `withNormalization` plugin.
 * To be safe, your plugins should not modify the original objects.
 *
 * @example
 *
 * ```typescript
 * const jsonLD = bundleJsonLD({
 *   documents: [
 *      serializeRecipeCollection(workOrLocation),
 *      serializeWebPage(workOrLocation),
 *   ],
 *   plugins: [
 *      withItemListPagination({ page: 1, pageSize: 3 })
 *   ]
 * });
 * ```
 */
export function bundleJsonLD({
  documents,
  plugins,
}: {
  documents: (JsonLD | undefined)[]
  plugins?: JsonLDPlugin[]
}): Schema.WithContext<Schema.Thing>[] {
  return [
    withMainEntityOfPage(), // 1. Add `mainEntityOfPage` for any objects w/ pages
    ...(plugins || []), // 2. Custom plugins
    withNormalization(), // 3. Normalize objects to reduce duplication
    withSchemaContext(), // 4: Add `@context` to all root objects
  ].reduce(
    (acc, transformer) => transformer(acc),
    documents.filter(hasValue),
  ) as Schema.WithContext<Schema.Thing>[]
}
